<template>
  <div class="container">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <h1 class="title has-text-centered">ຕິດຕາມເຄື່ອງ</h1>
        <div class="box">
          <div class="field is-grouped">
            <div class="control is-expanded">
              <input
                v-model="code"
                class="input is-large has-text-centered"
                type="text"
                placeholder="ປ້ອນເລກໃບບິນ"
                @keyup.enter="get()"
              />
            </div>
          </div>
          <div class="columns" style="padding: 3em" v-if="item">
            <div
              class="column has-text-centered"
              style="
                padding: 1.5em;
                font-size: 1.5em;
                background-color: #ff6622;
                color: #ffffff;
              "
            >
              ({{ item.from.code }}) {{ item.from.name }}
              <div style="font-size: 0.5em">
                {{ item.from.tel }}
              </div>
            </div>
            <div class="column has-text-centered is-2">
              <b-icon icon="arrow-right" size="is-large"></b-icon>
            </div>
            <div
              class="column has-text-centered"
              style="
                padding: 1.5em;
                font-size: 1.5em;
                background-color: #5555ff;
                color: #ffffff;
              "
            >
              ({{ item.to.code }}) {{ item.to.name }}
              <div style="font-size: 0.5em">
                {{ item.to.tel }}
              </div>
            </div>
          </div>
          <TrackingComponent :items="item.trackings" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import TrackingComponent from "../components/TrackingComponent.vue";
export default {
  name: "Track",
  components: { TrackingComponent },
  data() {
    return {
      code: null,
      item: null,
    };
  },
  mounted() {
    this.code = this.$route.params["code"];
    this.get();
  },
  methods: {
    get() {
      if (this.code) {
        axios
          .get("track/" + this.code)
          .then((r) => {
            if (r) {
              if (r.trackings) {
                this._.map(r.trackings, (i) => {
                  i.tag = i.date;
                  i.htmlMode = true;
                  i.type = "circle";
                  i.color = "green";
                  i.content = `
                <div>
                  <div style='text-align: left; font-size: 1.5em'>${i.title}</div>
                  <div style='text-align: left; font-size: 1em;color: #777777'>${i.description}</div>
                </div>
                `;
                });
              }
              this.item = r;
            }
          })
          .catch((e) => {
            swal.fire(e, "", "info");
          });
      }
    },
  },
};
</script>